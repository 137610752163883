import React from "react"
import { useStateContext } from "@context/stateContext"
import bgFarbe from "@images/darkmode-farbe.jpg"

const LobsterCursor: React.FC<Props> = ({}) => {
  const { setDarkMode, darkMode } = useStateContext()

  const handleDarkMode = state => {
    setDarkMode(state)
    if (state) {
      window.localStorage.theme = "dark"
      document.documentElement.classList.add("dark")
    } else {
      window.localStorage.theme = "light"
      document.documentElement.classList.remove("dark")
    }
  }

  const checkDarkMode = () => {
    if (window.localStorage.theme === "dark" || (!("theme" in window.localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      document.documentElement.classList.add("dark")
      setDarkMode(true)
    } else {
      document.documentElement.classList.remove("dark")
      setDarkMode(false)
    }
  }

  React.useEffect(() => {
    checkDarkMode()
  }, [])

  return (
    <div className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full gap-1 bg-black dark-mode-bar text-16px/115 h-spacing-40">
      {darkMode && <img className="absolute inset-0 z-0 object-cover w-full h-full" src={bgFarbe} alt="" />}
      <span className="relative z-10 inline-block text-white dark:text-black font-copy">{darkMode ? "Sieht dir zu düster aus? " : "Zu viel des Bunten?"}</span>
      <button className="relative z-10 text-white underline dark:text-black font-copy hover:cursor-none" onClick={() => handleDarkMode(!darkMode)}>
        {darkMode ? "Hier gibt’s mehr Farbe" : "Paint it black"}
      </button>
    </div>
  )
}

export default LobsterCursor
