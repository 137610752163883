import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useStateContext } from "@context/stateContext"
import getTransition from "./../../utils/getTransition"
interface Props {
  variant?: "main" | "single"
}
export const Footer: React.FC<Props> = ({ variant = "main" }) => {
  const { setMouseInteracting, setMouseWhite, language, setTsA } = useStateContext()
  const data = useStaticQuery(graphql`
    query Footer {
      wp {
        acfOptionsFooter {
          themeOptionsFooter {
            footerMenu {
              menuItem {
                url
                title
                target
              }
            }
            footerMenuEn {
              menuItem {
                url
                title
                target
              }
            }
          }
        }
      }
    }
  `)
  const { footerMenu, footerMenuEn } = data.wp.acfOptionsFooter.themeOptionsFooter

  const isVariantSingle = variant === "single"

  const navItemClick = (e, to, transitionState) => {
    e.preventDefault()
    setTsA(transitionState)
    getTransition(to, transitionState)
  }

  return (
    <footer
      onMouseEnter={() => setMouseWhite(true)}
      onMouseLeave={() => setMouseWhite(false)}
      className={`relative z-20 ${isVariantSingle ? "py-spacing-30 3xl:py-spacing-master" : "py-spacing-100"} w-full text-white bg-black min-h-screen-xl px-spacing-15 font-copy md:px-offset-xs `}
    >
      <div className="relative mx-auto">
        {isVariantSingle && (
          <Link
            to="/"
            className="inline-block underline text-20px/140 md:text-16px/150 lg:text-20px/140 3xl:text-32px/140 mb-spacing-125 3xl:mb-spacing-175"
            onMouseEnter={() => setMouseInteracting(true)}
            onMouseLeave={() => setMouseInteracting(false)}
            onClick={e => navItemClick(e, language === "de" ? "/" : "/en/", "home-back")}
          >
            {language === "de" ? "Zur Home" : "Back to home"}
          </Link>
        )}
        {/* <div className="relative mx-auto max-w-max">
          <p className="block text-center font-primary text-[78px] md:text-100px/115 md:text-[123px] lg:text-160px/115">D+M</p>
        </div> */}
        <div className={`${isVariantSingle ? "pt-spacing-30" : "pt-spacing-75 md:pt-spacing-40"} flex flex-col justify-center md:flex-row gap-y-spacing-10 gap-x-spacing-master `}>
          {/* map bottom links into anchor tags with classname underline */}
          {language === "de" &&
            footerMenu?.length > 0 &&
            footerMenu.map(({ menuItem }, index) => {
              const { url, title, target } = menuItem
              return (
                <Link
                  onClick={e => navItemClick(e, url, "clip-bot-top")}
                  to={url}
                  key={index}
                  className="underline text-20px/115 md:text-16px/115 lg:text-20px/115"
                  onMouseEnter={() => setMouseInteracting(true)}
                  onMouseLeave={() => setMouseInteracting(false)}
                >
                  {title}
                </Link>
              )
            })}
          {language === "en" &&
            footerMenuEn?.length > 0 &&
            footerMenuEn.map(({ menuItem }, index) => {
              const { url, title, target } = menuItem
              return (
                <Link
                  onClick={e => navItemClick(e, url, "clip-bot-top")}
                  to={url}
                  key={index}
                  className="underline text-20px/115 md:text-16px/115 lg:text-20px/115"
                  onMouseEnter={() => setMouseInteracting(true)}
                  onMouseLeave={() => setMouseInteracting(false)}
                >
                  {title}
                </Link>
              )
            })}
        </div>
      </div>
    </footer>
  )
}
